export class JamesBRules {
    public id: string;
    public checkEnv: boolean;
    public logError: boolean;
    public checkUser: boolean;
    public showInformer: boolean;
    public alwaysShow: boolean;
    public checkBuildVersion: boolean;
    public maxOldVersion: string;
    public informerKillUser: boolean;
    public showWebMessage: boolean;
    public webMessage: string;
    public queryLog: boolean;
    public release: string;
    public isCanary: boolean;
    public LastPTM: boolean;
    public checkIni: boolean;
    public valid: boolean;
    public details: any[];
}
