import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { PoNotificationService } from '@po-ui/ng-components';
import { Observable } from 'rxjs';
import { MainService } from '../app.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard  {

    constructor(
            private mainService: MainService,
            private router: Router,
            private poNotification: PoNotificationService) { }
            
  canActivate( route: ActivatedRouteSnapshot, state: RouterStateSnapshot ): Observable<boolean> | boolean {
    if (this.mainService.checkToken()) {
        return true;
    } else {
        this.router.navigate(['login']);
        return false;
    }
  }

  canActivateChild( croute: ActivatedRouteSnapshot, cstate: RouterStateSnapshot ): Observable<boolean> | boolean {
    if (this.mainService.checkToken()) {
        // if (this.mainService.checkArea()) {
        //     return true;
        // } else {
        //     this.poNotification.warning("Você deve selecionar uma área de trabalho para continuar o acesso!");
        //     window.location.reload();

        //     this.router.navigate(['menu']);
        //     return false;
        // }
        return true;
    } else {
        this.router.navigate(['login']);
        return false;
    }
}

  canLoad (rota: Route): Observable<boolean>|boolean {
      if (this.mainService.checkToken()) {
          return true;
      } else {
          this.router.navigate(['login']);
          return false;
      }
  }
}
