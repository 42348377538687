<po-page-default class="full_screen" p-title="Suporte">

    <div class="po-row po-md-lg-sm-12">
        <p class="support_header">STATUS - ESP</p>
        <br>
        <div class="po-row">
            <iframe class="iframe_box" width="1060" height="300" src="https://status.engpro.totvs.com.br/status/esp"></iframe>
        </div>
    </div>

    <po-divider class="full_screen"></po-divider>

    <div class="po-row po-md-lg-sm-12">
        <p class="support_header">STATUS - LICENSES</p>
        <br>
        <div class="po-row">
            <iframe class="iframe_box" width="1060" height="300" src="https://status.engpro.totvs.com.br/status/license"></iframe>
        </div>
    </div>

</po-page-default>