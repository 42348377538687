import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { PoModule } from '@po-ui/ng-components';

import { JamesAdminRouting } from './james-admin.routing';
import { JamesAdminComponent } from './james-admin.component';

@NgModule({
  declarations: [JamesAdminComponent],
  imports: [
    CommonModule,
    FormsModule,
    CommonModule,
    PoModule,
    JamesAdminRouting
  ]
})
export class JamesAdminModule { }
